import { Component, OnInit } from "@angular/core";
import { DataServisiService } from "src/app/data-servisi.service";

@Component({
  selector: "app-muze",
  templateUrl: "./muze.component.html",
  styleUrls: ["./muze.component.scss"]
})
export class MuzeComponent implements OnInit {
  constructor(public servis: DataServisiService) {}

  data = [
    {
      resim: "/muze/3/12.jpg"
    },
    {
      resim: "/muze/3/14.jpg"
    },
    {
      resim: "/muze/3/6.jpg"
    },
    {
      resim: "/muze/3/GS (1).jpg"
    },
    {
      resim: "/muze/3/IMG_4728.jpg"
    },
    {
      resim: "/muze/3/IMG_4729.jpg"
    },
    {
      resim: "/muze/3/MI 0093.jpg"
    },
    {
      resim: "/muze/3/MI 0094.jpg"
    },
    {
      resim: "/muze/3/MI 0095.jpg"
    },
    {
      resim: "/muze/3/MI 0096.jpg"
    },
    {
      resim: "/muze/3/MI 0103.jpg"
    },
    {
      resim: "/muze/3/MI 0106.jpg"
    },
    {
      resim: "/muze/3/MI 0111.jpg"
    },
    {
      resim: "/muze/3/MI 0112.jpg"
    },
    {
      resim: "/muze/3/MI 0113.jpg"
    },
    {
      resim: "/muze/3/MI 0114.jpg"
    },
    {
      resim: "/muze/3/museum.jpg"
    },
    {
      resim: "/muze/3/s01_8593.jpg"
    },
    {
      resim: "/muze/3/s01_8644.jpg"
    },
    {
      resim: "/muze/3/s01_8645.jpg"
    },
    {
      resim: "/muze/3/s01_8684.jpg"
    },
    {
      resim: "/muze/3/s01_8686.jpg"
    },
    {
      resim: "/muze/2/image00001.jpg"
    },
    {
      resim: "/muze/2/image00002.jpg"
    },
    {
      resim: "/muze/2/image00003.jpg"
    },
    {
      resim: "/muze/2/image00004.jpg"
    },
    {
      resim: "/muze/2/image00005.jpg"
    },
    {
      resim: "/muze/2/image00006.jpg"
    },
    {
      resim: "/muze/2/image00007.jpg"
    },
    {
      resim: "/muze/2/image00008.jpg"
    },
    {
      resim: "/muze/2/image00009.jpg"
    },
    {
      resim: "/muze/2/image00010.jpg"
    },
    {
      resim: "/muze/2/image00011.jpg"
    },
    {
      resim: "/muze/2/image00012.jpg"
    }
  ];

  ngOnInit() {}
}
