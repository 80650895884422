import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { HeaderFooterComponent } from "./alanlar/header-footer/header-footer.component";
import { FooterComponent } from "./alanlar/footer/footer.component";
import { HeaderComponent } from "./alanlar/header/header.component";
import { HomeComponent } from "./sayfalar/home/home.component";
import { AnaSayfaSliderComponent } from "./bolmeler/ana-sayfa-slider/ana-sayfa-slider.component";
import { TestimonialsComponent } from "./bolmeler/testimonials/testimonials.component";
import { IletisimComponent } from "./sayfalar/iletisim/iletisim.component";
import { ZiyaretciDefteriComponent } from "./sayfalar/ziyaretci-defteri/ziyaretci-defteri.component";
import { AnaSayfaZiyaretciDefteriComponent } from "./bolmeler/ana-sayfa-ziyaretci-defteri/ana-sayfa-ziyaretci-defteri.component";
import { HotelComponent } from "./sayfalar/hotel/hotel.component";
import { RestaurantComponent } from "./sayfalar/restaurant/restaurant.component";
import { DugunSalonuComponent } from "./sayfalar/dugun-salonu/dugun-salonu.component";
import { SarapMahzeniComponent } from "./sayfalar/sarap-mahzeni/sarap-mahzeni.component";
import { MuzeComponent } from "./sayfalar/muze/muze.component";
import { KompleksComponent } from "./sayfalar/kompleks/kompleks.component";
import { SafePipe } from "./data-servisi.service";
import { AnaSayfaHakkimizdaComponent } from "./bolmeler/ana-sayfa-hakkimizda/ana-sayfa-hakkimizda.component";
import { ServislerComponent } from "./sayfalar/servisler/servisler.component";
import { HakkimizdaComponent } from "./sayfalar/hakkimizda/hakkimizda.component";
import { FormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";

@NgModule({
  declarations: [
    AppComponent,
    HeaderFooterComponent,
    FooterComponent,
    HeaderComponent,
    HomeComponent,
    AnaSayfaSliderComponent,
    TestimonialsComponent,
    IletisimComponent,
    ZiyaretciDefteriComponent,
    AnaSayfaZiyaretciDefteriComponent,
    HotelComponent,
    RestaurantComponent,
    DugunSalonuComponent,
    SarapMahzeniComponent,
    MuzeComponent,
    KompleksComponent,
    SafePipe,
    AnaSayfaHakkimizdaComponent,
    ServislerComponent,
    HakkimizdaComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: "serverApp" }),
    AppRoutingModule,
    FormsModule,
    HttpClientModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {}
