import { Component, OnInit } from "@angular/core";
import { DataServisiService } from "src/app/data-servisi.service";

@Component({
  selector: "app-hotel",
  templateUrl: "./hotel.component.html",
  styleUrls: ["./hotel.component.scss"]
})
export class HotelComponent implements OnInit {
  constructor(public servis: DataServisiService) {}

  ngOnInit() {}

  data = [
    {
      resim: "/rooms/5 — копия.jpg"
    },
    {
      resim: "/rooms/GS (5).jpg"
    },
    {
      resim: "/rooms/NEF_1556.jpg"
    },
    {
      resim: "/rooms/NEF_4795.jpg"
    },
    {
      resim: "/rooms/NEF_4798.jpg"
    },
    {
      resim: "/rooms/NEF_4799.jpg"
    },
    {
      resim: "/rooms/NEF_4800.jpg"
    },
    {
      resim: "/rooms/NEF_4804.jpg"
    },
    {
      resim: "/rooms/NEF_4808.jpg"
    },
    {
      resim: "/rooms/NEF_4810.jpg"
    },
    {
      resim: "/rooms/NEF_4811.jpg"
    },
    {
      resim: "/rooms/NEF_4814.jpg"
    },
    {
      resim: "/rooms/NEF_4821.jpg"
    },
    {
      resim: "/rooms/NEF_4826.jpg"
    },
    {
      resim: "/rooms/NEF_4827.jpg"
    },
    {
      resim: "/rooms/NEF_4832.jpg"
    },
    {
      resim: "/rooms/NEF_4833.jpg"
    },
    {
      resim: "/rooms/NEF_4838.jpg"
    },
    {
      resim: "/rooms/NEF_4839.jpg"
    },
    {
      resim: "/rooms/NEF_4841.jpg"
    },
    {
      resim: "/rooms/NEF_4842.jpg"
    },
    {
      resim: "/rooms/NEF_4852.jpg"
    },
    {
      resim: "/rooms/NEF_4857.jpg"
    },
    {
      resim: "/rooms/NEF_4859.jpg"
    },
    {
      resim: "/rooms/NEF_4862.jpg"
    },
    {
      resim: "/rooms/NEF_4863.jpg"
    },
    {
      resim: "/rooms/NEF_4865.jpg"
    },
    {
      resim: "/rooms/NEF_4867.jpg"
    },
    {
      resim: "/rooms/NEF_4868.jpg"
    },
    {
      resim: "/rooms/NEF_4873.jpg"
    },
    {
      resim: "/rooms/NEF_4874.jpg"
    },
    {
      resim: "/rooms/NEF_4877.jpg"
    },
    {
      resim: "/rooms/NEF_4878.jpg"
    },
    {
      resim: "/rooms/NEF_4880.jpg"
    },
    {
      resim: "/rooms/NEF_4881.jpg"
    },
    {
      resim: "/rooms/NEF_4882.jpg"
    },
    {
      resim: "/rooms/NEF_4884.jpg"
    },
    {
      resim: "/rooms/NEF_4885.jpg"
    },
    {
      resim: "/rooms/NEF_4886.jpg"
    },
    {
      resim: "/rooms/NEF_4890.jpg"
    },
    {
      resim: "/rooms/NEF_4898.jpg"
    },
    {
      resim: "/rooms/NEF_4900.jpg"
    },
    {
      resim: "/rooms/NEF_4901.jpg"
    },
    {
      resim: "/rooms/NEF_4902.jpg"
    },
    {
      resim: "/rooms/NEF_4905.jpg"
    },
    {
      resim: "/rooms/NEF_4911.jpg"
    },
    {
      resim: "/rooms/NEF_4918.jpg"
    },
    {
      resim: "/rooms/NEF_4919.jpg"
    },
    {
      resim: "/rooms/NEF_4921.jpg"
    },
    {
      resim: "/rooms/NEF_4925.jpg"
    },
    {
      resim: "/rooms/NEF_4928.jpg"
    },
    {
      resim: "/rooms/NEF_4930.jpg"
    },
    {
      resim: "/rooms/NEF_4931.jpg"
    },
    {
      resim: "/rooms/NEF_4933.jpg"
    },
    {
      resim: "/rooms/NEF_4934.jpg"
    },
    {
      resim: "/rooms/NEF_4935.jpg"
    },
    {
      resim: "/rooms/NEF_4938.jpg"
    },
    {
      resim: "/rooms/NEF_4939.jpg"
    },
    {
      resim: "/rooms/NEF_4941.jpg"
    },
    {
      resim: "/rooms/NEF_4945.jpg"
    },
    {
      resim: "/rooms/NEF_4947.jpg"
    },
    {
      resim: "/rooms/NEF_4950.jpg"
    },
    {
      resim: "/rooms/NEF_4951.jpg"
    },
    {
      resim: "/rooms/NEF_4953.jpg"
    },
    {
      resim: "/rooms/NEF_4957.jpg"
    },
    {
      resim: "/rooms/NEF_5024.jpg"
    },
    {
      resim: "/rooms/NEF_5025.jpg"
    },
    {
      resim: "/rooms/NEF_5027.jpg"
    },
    {
      resim: "/rooms/NEF_5028.jpg"
    },
    {
      resim: "/rooms/NEF_5036.jpg"
    },
    {
      resim: "/rooms/NEF_5037.jpg"
    },
    {
      resim: "/rooms/NEF_5038.jpg"
    },
    {
      resim: "/rooms/NEF_5039.jpg"
    },
    {
      resim: "/rooms/NEF_5044.jpg"
    },
    {
      resim: "/rooms/NEF_5045.jpg"
    },
    {
      resim: "/rooms/NEF_5046.jpg"
    },
    {
      resim: "/rooms/NEF_5050.jpg"
    },
    {
      resim: "/rooms/NEF_5051.jpg"
    },
    {
      resim: "/rooms/NEF_5052.jpg"
    },
    {
      resim: "/rooms/NEF_5058.jpg"
    },
    {
      resim: "/rooms/NEF_5063.jpg"
    },
    {
      resim: "/rooms/NEF_5064.jpg"
    },
    {
      resim: "/rooms/NEF_5066.jpg"
    },
    {
      resim: "/rooms/NEF_5076.jpg"
    },
    {
      resim: "/rooms/NEF_5078.jpg"
    },
    {
      resim: "/rooms/NEF_5079.jpg"
    },
    {
      resim: "/rooms/NEF_5083.jpg"
    },
    {
      resim: "/rooms/NEF_5088.jpg"
    },
    {
      resim: "/rooms/NEF_5089.jpg"
    },
    {
      resim: "/rooms/NEF_5090.jpg"
    },
    {
      resim: "/rooms/NEF_5091.jpg"
    },
    {
      resim: "/rooms/NEF_5092.jpg"
    },
    {
      resim: "/rooms/NEF_5093.jpg"
    },
    {
      resim: "/rooms/NEF_5094.jpg"
    },
    {
      resim: "/rooms/NEF_5095.jpg"
    },
    {
      resim: "/rooms/NEF_5106.jpg"
    },
    {
      resim: "/rooms/NEF_5107.jpg"
    },
    {
      resim: "/rooms/NEF_5114.jpg"
    },
    {
      resim: "/rooms/NEF_5116.jpg"
    },
    {
      resim: "/rooms/NEF_5119.jpg"
    },
    {
      resim: "/rooms/NEF_5120.jpg"
    },
    {
      resim: "/rooms/NEF_5125.jpg"
    },
    {
      resim: "/rooms/NEF_5127.jpg"
    },
    {
      resim: "/rooms/NEF_5129.jpg"
    },
    {
      resim: "/rooms/NEF_5132.jpg"
    },
    {
      resim: "/rooms/NEF_5134.jpg"
    },
    {
      resim: "/rooms/NEF_5137.jpg"
    },
    {
      resim: "/rooms/NEF_5142.jpg"
    },
    {
      resim: "/rooms/NEF_5144.jpg"
    },
    {
      resim: "/rooms/NEF_5151.jpg"
    },
    {
      resim: "/rooms/NEF_5152.jpg"
    },
    {
      resim: "/rooms/NEF_5155.jpg"
    },
    {
      resim: "/rooms/NEF_5156.jpg"
    },
    {
      resim: "/rooms/NEF_5160.jpg"
    },
    {
      resim: "/rooms/NEF_5162.jpg"
    },
    {
      resim: "/rooms/NEF_5164.jpg"
    },
    {
      resim: "/rooms/NEF_5165.jpg"
    },
    {
      resim: "/rooms/NEF_5166.jpg"
    },
    {
      resim: "/rooms/NEF_5172.jpg"
    },
    {
      resim: "/rooms/NEF_5173.jpg"
    },
    {
      resim: "/rooms/NEF_5174.jpg"
    },
    {
      resim: "/rooms/NEF_5175.jpg"
    },
    {
      resim: "/rooms/NEF_5176.jpg"
    },
    {
      resim: "/rooms/NEF_5180.jpg"
    },
    {
      resim: "/rooms/NEF_5182.jpg"
    },
    {
      resim: "/rooms/NEF_5183.jpg"
    },
    {
      resim: "/rooms/NEF_5184.jpg"
    },
    {
      resim: "/rooms/s01_8762.jpg"
    }
  ];
}
