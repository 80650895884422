import {
  Component,
  AfterViewInit,
  OnInit,
  PLATFORM_ID,
  Inject
} from "@angular/core";
import { Router, NavigationEnd, ActivatedRoute } from "@angular/router";

import { DataServisiService } from "./data-servisi.service";
import { isPlatformBrowser, isPlatformServer } from "@angular/common";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"]
})
export class AppComponent implements OnInit, AfterViewInit {
  title = "GAGAUZ SOFRASI";

  constructor(
    public servis: DataServisiService,
    private router: Router,
    public route: ActivatedRoute,

    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    this.router.events.subscribe(val => {
      if (val instanceof NavigationEnd === true) {
        console.log("url degisti", this.router.url);

        this.servis.menugizli = true;
        setTimeout(() => {
          this.servis.menugizli = false;
        }, 1000);
      }
    });

    this.router.events.subscribe((val: any) => {
      if (val instanceof NavigationEnd === true) {
        this.servis.aktif_link = val.url;

        let top = 0;
        if (isPlatformBrowser(this.platformId)) {
          document
            .querySelector('[data-target="#navbarContent"]')
            .setAttribute("aria-expanded", "false");
          document.querySelector('[data-target="#navbarContent"]').className =
            "navbar-toggler collapsed";
          document.querySelector("#navbarContent").className =
            "navbar-collapse justify-content-between collapse";
          window.scroll({
            left: 0,
            top: top,
            behavior: "smooth"
          });
        }
      }
    });
  }

  c() {
    document.querySelectorAll('a[href^="#"]').forEach(anchor => {
      anchor.addEventListener("click", function(e) {
        e.preventDefault();

        document.querySelector(this.getAttribute("href")).scrollIntoView({
          behavior: "smooth"
        });
      });
    });
  }
  ngOnInit() {
    setTimeout(() => {
      if (isPlatformBrowser(this.platformId)) {
        this.c();
        setTimeout(() => {}, 1000);
      }
    }, 1000);
  }

  ngAfterViewInit() {}
}
