import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { HeaderFooterComponent } from "./alanlar/header-footer/header-footer.component";
import { HomeComponent } from "./sayfalar/home/home.component";
import { IletisimComponent } from "./sayfalar/iletisim/iletisim.component";
import { KompleksComponent } from "./sayfalar/kompleks/kompleks.component";
import { SarapMahzeniComponent } from "./sayfalar/sarap-mahzeni/sarap-mahzeni.component";
import { DugunSalonuComponent } from "./sayfalar/dugun-salonu/dugun-salonu.component";
import { HotelComponent } from "./sayfalar/hotel/hotel.component";
import { MuzeComponent } from "./sayfalar/muze/muze.component";
import { RestaurantComponent } from "./sayfalar/restaurant/restaurant.component";
import { ServislerComponent } from "./sayfalar/servisler/servisler.component";
import { HakkimizdaComponent } from "./sayfalar/hakkimizda/hakkimizda.component";
import { ZiyaretciDefteriComponent } from "./sayfalar/ziyaretci-defteri/ziyaretci-defteri.component";

const routes: Routes = [
  {
    path: "",
    component: HeaderFooterComponent,
    children: [
      {
        path: "",
        component: HomeComponent,
        pathMatch: "full"
      },
      {
        path: "contact",
        component: IletisimComponent,
        pathMatch: "full"
      },
      {
        path: "complex",
        component: KompleksComponent,
        pathMatch: "full"
      },
      {
        path: "wine-cellar",
        component: SarapMahzeniComponent,
        pathMatch: "full"
      },
      {
        path: "wedding-venue",
        component: DugunSalonuComponent,
        pathMatch: "full"
      },
      {
        path: "rooms",
        component: HotelComponent,
        pathMatch: "full"
      },
      {
        path: "museum",
        component: MuzeComponent,
        pathMatch: "full"
      },
      {
        path: "restorant",
        component: RestaurantComponent,
        pathMatch: "full"
      },
      {
        path: "uslugi",
        component: ServislerComponent,
        pathMatch: "full"
      },
      {
        path: "o-nas",
        component: HakkimizdaComponent,
        pathMatch: "full"
      },
      {
        path: "guestbook",
        component: ZiyaretciDefteriComponent,
        pathMatch: "full"
      }
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      anchorScrolling: "enabled"
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
