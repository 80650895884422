import { Injectable, PipeTransform, Pipe } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import {
  DomSanitizer,
  SafeHtml,
  SafeResourceUrl,
  SafeScript,
  SafeStyle,
  SafeUrl,
  Title
} from "@angular/platform-browser";

@Pipe({
  name: "safe"
})
export class SafePipe implements PipeTransform {
  constructor(protected sanitizer: DomSanitizer) {}

  public transform(
    value: any,
    type: string
  ): SafeHtml | SafeStyle | SafeScript | SafeUrl | SafeResourceUrl {
    switch (type) {
      case "html":
        return this.sanitizer.bypassSecurityTrustHtml(value);
      case "style":
        return this.sanitizer.bypassSecurityTrustStyle(value);
      case "script":
        return this.sanitizer.bypassSecurityTrustScript(value);
      case "url":
        return this.sanitizer.bypassSecurityTrustUrl(value);
      case "resourceUrl":
        return this.sanitizer.bypassSecurityTrustResourceUrl(value);
      default:
        throw new Error(`Invalid safe type specified: ${type}`);
    }
  }
}

@Injectable({
  providedIn: "root"
})
export class DataServisiService {
  constructor(private sanitization: DomSanitizer, public http: HttpClient) {}

  menugizli = false;
  aktif_link = "";

  photogen(res) {
    return this.sanitization.bypassSecurityTrustStyle(`url("${res}")`);
  }
  safeurlgen(res) {
    return this.sanitization.bypassSecurityTrustUrl(`${res}`);
  }
  safestylegen(res) {
    return this.sanitization.bypassSecurityTrustStyle(`${res}`);
  }

  aktif_dil = "tr";
  ceviriler = {};

  ceviri(kod, def) {
    if (this.ceviriler[this.aktif_dil] === undefined) return def;
    if (this.ceviriler[this.aktif_dil].ceviriler === undefined) return def;
    if (this.ceviriler[this.aktif_dil].ceviriler[kod] === undefined) return def;
    return this.ceviriler[this.aktif_dil].ceviriler[kod] || def;
  }

  get(link, sayi = 0) {
    return new Promise(resolve => {
      this.http.get(link).subscribe(
        data => {
          resolve(data);
        },
        error => {
          // console.log(error);
          ++sayi;
          if (sayi > 3) {
            resolve(undefined);
          } else {
            setTimeout(async () => {
              let sonuc = await this.get(link, sayi);
              resolve(sonuc);
            }, 200);
          }
        }
      );
    });
  }

  post(link, data) {
    return new Promise(resolve => {
      this.http.post(link, data).subscribe(
        data => {
          resolve(data);
        },
        error => {
          resolve(undefined);
        }
      );
    });
  }

  getgetir(link: string, cb: any) {
    this.get2(link).subscribe(
      data => {
        cb(data);
      },
      error => {
        cb({});
      }
    );
  }

  postgetir(link: string, data: any, cb: any) {
    // console.log( 'postgetir' , link , data);
    this.post2(link, data).subscribe(
      data => {
        cb(data);
      },
      error => {
        cb({});
      }
    );
  }

  post2(link: string, data: any) {
    return this.http.post(link, data);
  }

  get2(link: string) {
    return this.http.get(link);
  }
}
