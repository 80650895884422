import { Component, OnInit } from '@angular/core';
import { DataServisiService } from 'src/app/data-servisi.service';

@Component({
  selector: 'app-sarap-mahzeni',
  templateUrl: './sarap-mahzeni.component.html',
  styleUrls: ['./sarap-mahzeni.component.scss']
})
export class SarapMahzeniComponent implements OnInit {

  constructor(public servis: DataServisiService) { }

  data = [
    { resim: '/wine_cellar/15.jpg' },
    { resim: '/wine_cellar/9.jpg' },
    { resim: '/wine_cellar/GS (4).jpg' },
    { resim: '/wine_cellar/NEF_1567.jpg' },
    { resim: '/wine_cellar/NEF_1574.jpg' },
    { resim: '/wine_cellar/NEF_4980.jpg' },
    { resim: '/wine_cellar/NEF_4981.jpg' },
    { resim: '/wine_cellar/NEF_4984.jpg' },
    { resim: '/wine_cellar/NEF_4985.jpg' },
    { resim: '/wine_cellar/NEF_4988.jpg' },
    { resim: '/wine_cellar/NEF_4991.jpg' },
    { resim: '/wine_cellar/s01_8519.jpg' },
    { resim: '/wine_cellar/s01_8526.jpg' },
    { resim: '/wine_cellar/s01_8532.jpg' },
    { resim: '/wine_cellar/s01_8534.jpg' }  ];

  ngOnInit() {
  }

}
