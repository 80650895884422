import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ana-sayfa-hakkimizda',
  templateUrl: './ana-sayfa-hakkimizda.component.html',
  styleUrls: ['./ana-sayfa-hakkimizda.component.scss']
})
export class AnaSayfaHakkimizdaComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
