import { Component, OnInit } from "@angular/core";
import { DataServisiService } from "src/app/data-servisi.service";

@Component({
  selector: "app-dugun-salonu",
  templateUrl: "./dugun-salonu.component.html",
  styleUrls: ["./dugun-salonu.component.scss"]
})
export class DugunSalonuComponent implements OnInit {
  constructor(public servis: DataServisiService) {}

  data = [
    { resim: "/wedding_venue/3.jpg" },
    { resim: "/wedding_venue/dugun_salonu.jpeg" },
    { resim: "/wedding_venue/favorit (1).jpg" },
    { resim: "/wedding_venue/favorit (3).jpg" },
    { resim: "/wedding_venue/IMG_1208.jpg" },
    { resim: "/wedding_venue/IMG_1211.jpg" },
    { resim: "/wedding_venue/IMG_1212.jpg" },
    { resim: "/wedding_venue/IMG_1278.jpg" },
    { resim: "/wedding_venue/IMG_1280.jpg" },
    { resim: "/wedding_venue/IMG_1480.jpg" },
    { resim: "/wedding_venue/IMG_4717.jpg" },
    { resim: "/wedding_venue/IMG_4723.jpg" },
    { resim: "/wedding_venue/IMG_4732.jpg" },
    { resim: "/wedding_venue/IMG_4733.jpg" },
    { resim: "/wedding_venue/IMG_4735.jpg" },
    { resim: "/wedding_venue/IMG_4751.jpg" },
    { resim: "/wedding_venue/IMG_5009.jpg" },
    { resim: "/wedding_venue/IMG_5681.jpg" },
    { resim: "/wedding_venue/IMG_5684.jpg" },
    { resim: "/wedding_venue/IMG_6431.jpg" },
    { resim: "/wedding_venue/s01_8861.jpg" }
  ];

  ngOnInit() {}
}
