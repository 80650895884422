import { Component, OnInit, AfterViewInit } from "@angular/core";
import { DataServisiService } from "src/app/data-servisi.service";

declare var $: any;
@Component({
  selector: "app-ziyaretci-defteri",
  templateUrl: "./ziyaretci-defteri.component.html",
  styleUrls: ["./ziyaretci-defteri.component.scss"]
})
export class ZiyaretciDefteriComponent implements OnInit, AfterViewInit {
  constructor(public servis: DataServisiService) {}

  ngAfterViewInit() {
    /* ------------------ OWL CAROUSEL ------------------ */

    var $productsSlider = $(".products-slider");

    $(".carousel").each(function() {
      var $Carousel = $(this);
      $Carousel.owlCarousel({
        loop: $Carousel.data("loop"),
        autoplay: false, // $Carousel.data("autoplay"),
        margin: $Carousel.data("space"),
        nav: $Carousel.data("nav"),
        dots: $Carousel.data("dots"),
        center: $Carousel.data("center"),
        dotsSpeed: $Carousel.data("speed"),
        responsive: {
          0: {
            items: 1
          },
          600: {
            items: $Carousel.data("slide-rs")
          },
          1000: {
            items: $Carousel.data("slide")
          }
        }
      });
    });

    $productsSlider.owlCarousel({
      thumbs: true,
      thumbsPrerendered: true,
      loop: true,
      margin: 0,
      autoplay: false,
      nav: false,
      dots: false,
      dotsSpeed: 200,
      responsive: {
        0: {
          items: 1
        },
        600: {
          items: 1
        },
        1000: {
          items: 1
        }
      }
    });
  }

  ngOnInit() {
    this.yenile();
  }

  hatavar2 = false;
  hatamesaji2 = "";
  gonderiliyor = false;
  gonderim_sonuclandi = false;
  gonderim_sonuc = "";
  gonderim_basarili = false;
  aktif_captcha: any = undefined;
  iletisim_datasi: any = {
    ad_soyad: "",
    eposta: "",
    konu: "",
    mesaj: ""
  };
  async gonder() {
    const gidecek_data = this.iletisim_datasi;
    this.hatavar2 = false;
    this.hatamesaji2 = "";
    if (
      this.aktif_captcha.girilen_data === undefined ||
      this.aktif_captcha.girilen_data === ""
    ) {
      this.hatavar2 = true;
      this.hatamesaji2 = this.servis.ceviri(
        "guvenlik_kodu_girilmemis",
        "Защитный код Не определено"
      );
      return;
    }
    if (gidecek_data.ad_soyad === undefined || gidecek_data.ad_soyad === "") {
      this.hatavar2 = true;
      this.hatamesaji2 = this.servis.ceviri(
        "adsoyad_girilmemis",
        "Имя фамилия Не определено"
      );
      return;
    }
    if (gidecek_data.eposta === undefined || gidecek_data.eposta === "") {
      this.hatavar2 = true;
      this.hatamesaji2 = this.servis.ceviri(
        "eposta_girilmemis",
        "E-маил Не определено"
      );
      return;
    }
    if (gidecek_data.konu === undefined || gidecek_data.konu === "") {
      this.hatavar2 = true;
      this.hatamesaji2 = this.servis.ceviri(
        "konu_girilmemis",
        "Тема Не определено"
      );
      return;
    }
    // if (gidecek_data.telefon === undefined || gidecek_data.telefon === "") {
    //   this.hatavar2 = true;
    //   this.hatamesaji2 = this.servis.ceviri(
    //     "telefon_girilmemis",
    //     "Telefon Girilmemiş"
    //   );
    //   return;
    // }
    if (gidecek_data.mesaj === undefined || gidecek_data.mesaj === "") {
      this.hatavar2 = true;
      this.hatamesaji2 = this.servis.ceviri(
        "mesaj_girilmemis",
        "Сообщение Не определено"
      );
      return;
    }
    const aktif_captcha_clone = JSON.parse(JSON.stringify(this.aktif_captcha));
    delete aktif_captcha_clone.data;
    gidecek_data.captcha = aktif_captcha_clone;

    console.log("gidecek_data", gidecek_data);
    this.gonderiliyor = true;
    const sonuc: any = await this.servis.post("/mail", {
      ne: gidecek_data
    });
    this.gonderim_sonuclandi = true;
    if (sonuc.ok === 1 || sonuc.ok === "1") {
      this.gonderim_basarili = true;
      this.iletisim_datasi = {
        ad_soyad: "",
        eposta: "",
        konu: "",
        mesaj: ""
      };
    } else {
      this.gonderim_basarili = false;
    }
    this.aktif_captcha = await this.servis.get("/captcha?a=" + Math.random());
    this.gonderim_sonuc = sonuc.sebep;

    this.gonderiliyor = false;

    console.log("sonuc", sonuc);
  }
  async captcha_yenile() {
    console.log("captcha_yenile");
    this.aktif_captcha = await this.servis.get("/captcha?a=" + Math.random());
    console.log("captcha_yenile2");
  }
  async yenile() {
    this.aktif_captcha = await this.servis.get("/captcha?a=" + Math.random());
  }

  e(metin) {
    if (metin === undefined) return "";
    return metin.replace(/\n/g, "<br>");
  }
}
