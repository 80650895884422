import { Component, OnInit, AfterViewInit } from "@angular/core";

declare var $: any;

@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.scss"]
})
export class FooterComponent implements OnInit, AfterViewInit {
  constructor() {}

  ngOnInit() {}

  ngAfterViewInit() {
    // siteFooter();
    // $(window).resize(function() {
    //   siteFooter();
    // });
    // function siteFooter() {
    //   var siteContent = $("#wrapperParallax");
    //   var contentParallax = $(".contentParallax");
    //   var siteFooter = $("#footerParallax");
    //   var siteFooterHeight = siteFooter.height();
    //   siteContent.css({
    //     "margin-bottom": siteFooterHeight
    //   });
    // }
  }
}
