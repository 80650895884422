import { Component, OnInit } from "@angular/core";
import { DataServisiService } from "src/app/data-servisi.service";

@Component({
  selector: "app-restaurant",
  templateUrl: "./restaurant.component.html",
  styleUrls: ["./restaurant.component.scss"]
})
export class RestaurantComponent implements OnInit {
  constructor(public servis: DataServisiService) {}

  data = [
    {
      	resim: "/restoran/23172838_2417283818496001_8312912243366776679_n.jpg"
    },
    {
      	resim: "/restoran/restaurant_resim.jpeg"
    },
    {
      	resim: "/restoran/s01_8472.jpg"
    },
    {
      	resim: "/restoran/s01_8482.jpg"
    },
    {
      	resim: "/menuler/MENU-1.jpg"
    },
    {
     	resim: "/menuler/MENU-7.jpg"
    }
  ];

  ngOnInit() {}
}
