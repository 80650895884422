import { Component, OnInit, AfterViewInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router"; // <-- do not forget to import

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"]
})
export class HomeComponent implements OnInit, AfterViewInit {
  constructor(private route: ActivatedRoute) {}

  private fragment: string;
  ngAfterViewInit(): void {
    try {
      console.log("this.fragment", this.fragment);
      document.querySelector("#" + this.fragment).scrollIntoView();
    } catch (e) {}
  }

  ngOnInit() {
    this.route.fragment.subscribe(fragment => {
      this.fragment = fragment;
    });
  }
}
