import { Component, OnInit } from "@angular/core";
import { DataServisiService } from "src/app/data-servisi.service";

@Component({
  selector: "app-hakkimizda",
  templateUrl: "./hakkimizda.component.html",
  styleUrls: ["./hakkimizda.component.scss"]
})
export class HakkimizdaComponent implements OnInit {
  constructor(public servis: DataServisiService) {}

  data = [
    { resim: "/complex/10.jpg" },
    { resim: "/complex/13.jpg" },
    { resim: "/complex/8.jpg" },
    { resim: "/complex/favorit (5).jpeg" },
    { resim: "/complex/favorit (5).jpg" },
    { resim: "/complex/GS (3).jpg" },
    { resim: "/complex/mi 0251.jpg" },
    { resim: "/complex/MK3_5273.jpg" },
    { resim: "/complex/MK3_5275.jpg" },
    { resim: "/complex/MK3_5286.jpg" },
    { resim: "/complex/MK3_5288.jpg" },
    { resim: "/complex/MK3_5301.jpg" },
    { resim: "/complex/MK3_5318.jpg" },
    { resim: "/complex/NEF_1596.jpg" },
    { resim: "/complex/NEF_4965.jpg" },
    { resim: "/complex/NEF_4969.jpg" },
    { resim: "/complex/NEF_4971.jpg" },
    { resim: "/complex/NEF_4974.jpg" },
    { resim: "/complex/NEF_4977.jpg" },
    { resim: "/complex/NEF_5004.jpg" },
    { resim: "/complex/NEF_5006.jpg" },
    { resim: "/complex/NEF_5012.jpg" },
    { resim: "/complex/NEF_5014.jpg" },
    { resim: "/complex/NEF_5017.jpg" },
    { resim: "/complex/NEF_5018.jpg" },
    { resim: "/complex/NEF_5019.jpg" },
    { resim: "/complex/NEF_5021.jpg" },
    { resim: "/complex/s01_8433.jpg" },
    { resim: "/complex/s01_8435.jpg" },
    { resim: "/complex/s01_8455.jpg" },
    { resim: "/complex/s01_8468.jpg" },
    { resim: "/complex/s01_8489.jpg" },
    { resim: "/complex/s01_8506.jpg" },
    { resim: "/complex/s01_8757.jpg" },
    { resim: "/complex/s01_8787.jpg" },
    { resim: "/complex/Terrace.jpg" },
    { resim: "/complex/БАНЕР 3.jpg" }
  ];

  ngOnInit() {}
}
