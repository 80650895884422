import { Component, OnInit, AfterViewInit } from "@angular/core";

declare var $: any;
declare var jQuery: any;
@Component({
  selector: "app-servisler",
  templateUrl: "./servisler.component.html",
  styleUrls: ["./servisler.component.scss"]
})
export class ServislerComponent implements OnInit, AfterViewInit {
  constructor() {}

  ngOnInit() {}
  ngAfterViewInit() {
    /* ------------------ OWL CAROUSEL ------------------ */

    var $productsSlider = $(".products-slider");

    $(".carousel").each(function() {
      var $Carousel = $(this);
      $Carousel.owlCarousel({
        loop: $Carousel.data("loop"),
        autoplay: $Carousel.data("autoplay"),
        margin: $Carousel.data("space"),
        nav: $Carousel.data("nav"),
        dots: $Carousel.data("dots"),
        center: $Carousel.data("center"),
        dotsSpeed: $Carousel.data("speed"),
        responsive: {
          0: {
            items: 1
          },
          600: {
            items: $Carousel.data("slide-rs")
          },
          1000: {
            items: $Carousel.data("slide")
          }
        }
      });
    });

    $productsSlider.owlCarousel({
      thumbs: true,
      thumbsPrerendered: true,
      loop: true,
      margin: 0,
      autoplay: false,
      nav: false,
      dots: false,
      dotsSpeed: 200,
      responsive: {
        0: {
          items: 1
        },
        600: {
          items: 1
        },
        1000: {
          items: 1
        }
      }
    });
  }
}
